import { Box } from "@chakra-ui/react";
import { Outlet } from "react-router-dom";
import Footer from "./footer";
import Header from "./header";

export default function LayoutMain() {
  return (
    <>
      <Header />
      <Box bg={"gray.100"}>
        <Outlet />
      </Box>
      <Footer />
    </>
  );
}
